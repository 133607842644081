<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <MySidebar active="orders" :user="body.userName" :image="body.image" />
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="left-profile">
          <div class="order-details mb-4">
            <h6>{{ $t("Order details") }}</h6>
            <div class="content-details d-flex justify-content-between align-items-center">
              <p v-if="one.orderStatus != 'done'">
                {{ $t("Up to max") }} {{ $delevryDate(one.doneDate) }}
              </p>
              <!-- <button @click="deleteo()" v-if="one.orderStatus == 'new'">
                <b-icon icon="x-circle" class=""></b-icon>
                {{ $t("Cancelling order") }}
              </button> -->
            </div>
          </div>

          <div class="current-orders py-4" v-if="one.orderStatus == 'new'">
            <div class="icons d-flex justify-content-center">
              <b-icon icon="circle-fill" class="orange circle-icon h5"></b-icon>
              <b-icon icon="dash" class="orange dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon"></b-icon>
              <b-icon icon="dash" class="orange dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="circle-fill" class="pink circle-icon h5"></b-icon>
              <b-icon icon="dash" class="pink dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="dash-lg" class="pink dash-icon"></b-icon>
              <b-icon icon="dash-lg" class="pink dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="pink dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="pink dash-icon"></b-icon>
              <b-icon icon="dash" class="pink dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="circle-fill" class="pink circle-icon h5"></b-icon>
              <b-icon icon="dash" class="pink dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="dash-lg" class="pink dash-icon"></b-icon>
              <b-icon icon="dash-lg" class="pink dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="pink dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="pink dash-icon"></b-icon>
              <b-icon icon="dash" class="pink dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="circle-fill" class="pink circle-icon h5"></b-icon>
            </div>
            <div class="order-done d-flex justify-content-center pb-4 position-relative">
              <span class="cart"> {{ $t("request is done") }} </span>
              <span class="address"> {{ $t("Underway") }} </span>
              <span class="payingg"> {{ $t("Delivery is in progress") }} </span>
              <span class="requestt"> {{ $t("sent delivered handed") }}</span>
            </div>
          </div>

          <div class="current-orders py-4" v-if="one.orderStatus == 'delivery'">
            <div class="icons d-flex justify-content-center">
              <b-icon icon="circle-fill" class="orange circle-icon h5"></b-icon>
              <b-icon icon="dash" class="orange dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon"></b-icon>
              <b-icon icon="dash" class="orange dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="circle-fill" class="orange circle-icon h5"></b-icon>
              <b-icon icon="dash" class="orange dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon"></b-icon>
              <b-icon icon="dash" class="orange dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="circle-fill" class="orange circle-icon h5"></b-icon>
              <b-icon icon="dash" class="pink dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="dash-lg" class="pink dash-icon"></b-icon>
              <b-icon icon="dash-lg" class="pink dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="pink dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="pink dash-icon"></b-icon>
              <b-icon icon="dash" class="pink dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="circle-fill" class="pink circle-icon h5"></b-icon>
            </div>
            <div class="order-done d-flex justify-content-center pb-4 position-relative">
              <span class="cart"> {{ $t("request is done") }} </span>
              <span class="address"> {{ $t("Underway") }} </span>
              <span class="payingg"> {{ $t("Delivery is in progress") }} </span>
              <span class="requestt"> {{ $t("sent delivered handed") }}</span>
            </div>
          </div>

          <div class="current-orders py-4" v-if="one.orderStatus == 'done'">
            <div class="icons d-flex justify-content-center">
              <b-icon icon="circle-fill" class="orange circle-icon h5"></b-icon>
              <b-icon icon="dash" class="orange dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon"></b-icon>
              <b-icon icon="dash" class="orange dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="circle-fill" class="orange circle-icon h5"></b-icon>
              <b-icon icon="dash" class="orange dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon"></b-icon>
              <b-icon icon="dash" class="orange dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="circle-fill" class="orange circle-icon h5"></b-icon>
              <b-icon icon="dash" class="orange dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon d-block d-lg-none"></b-icon>
              <b-icon icon="dash-lg" class="orange dash-icon"></b-icon>
              <b-icon icon="dash" class="orange dash-icon d-none d-lg-block"></b-icon>
              <b-icon icon="circle-fill" class="orange circle-icon h5"></b-icon>
            </div>
            <div class="order-done d-flex justify-content-center pb-4 position-relative">
              <span class="cart"> {{ $t("request is done") }} </span>
              <span class="address"> {{ $t("Underway") }} </span>
              <span class="payingg"> {{ $t("Delivery is in progress") }} </span>
              <span class="requestt"> {{ $t("sent delivered handed") }}</span>
            </div>
          </div>

          <div class="current-orders d-flex align-items-center mb-5 itemorder-phone" v-for="item of one.cartItemsList">
            <img :src="$baseUploadURL + item.itemImage" style="width: 125px; height: 125px" alt="" />
            <div class="content w-100">
              <div class="content1 d-flex justify-content-between">
                <h6>{{ $getByLang(item.itemName) }}</h6>
                <span>
                  {{ $t("Quantity") }} :
                  <b> {{ $codePadding(item.quantity) }} </b>
                </span>
              </div>
              <div class="content2 d-flex justify-content-between">
                <span>
                  <b>{{ Math.round(item.total) }}{{ $t("r.s") }}</b>

                  <del class="price" style="padding: 0px 10px; font-size: 16px; color: #a49d9d" v-if="item.offerId">
                    {{
                    Math.round(item.priceBefforOffer * item.quantity) }}
                    {{ $t("SAR") }}</del>
                </span>
              </div>
            </div>
          </div>
          <h6 class="mb-4">{{ $t("Order details") }}</h6>
          <div class="d-flex justify-content-between total-products">
            <div class="order-number">
              <div class="order-content" style="width: 100%">
                <div class="date d-flex justify-content-between mb-2">
                  <p>{{ $t("The date of application") }}</p>
                  <span class="dash"> </span>
                  <span>{{ $formatDate(one.doneDate) }}</span>
                </div>
                <div class="date d-flex justify-content-between mb-2">
                  <p>{{ $t("order number") }}</p>
                  <span class="dash"> </span>
                  <span>{{ one.code }}</span>
                </div>
                <div class="date d-flex justify-content-between">
                  <p>{{ $t("payment method") }}</p>
                  <span class="dash"></span>
                  <span>{{ getType(one.paymentType) }}</span>
                </div>
                <div class="date d-flex justify-content-between"
                  v-if="one.paymentType == 'online' || one.paymentType == 'mada' || one.paymentType == 'stc' || one.paymentType == 'apple' || one.paymentType == 'tamara'">
                  <p>{{ $t("Payment status") }}</p>
                  <span class="dash"></span>
                  <span>{{ one.paymentCCdone ? "Done" : "Fail" }}</span>
                </div>
                <div class="date d-flex justify-content-between"
                  v-if="one.paymentType == 'online' || one.paymentType == 'mada' || one.paymentType == 'stc' || one.paymentType == 'apple'">
                  <span>{{ one.paymentCCID }}</span>
                </div>
                <div class="date d-flex justify-content-between"
                  v-if="one.paymentType == 'online' || one.paymentType == 'mada' || one.paymentType == 'stc' || one.paymentType == 'apple'">
                  <span>{{ one.paymentCCdoneDescription ? one.paymentCCdoneDescription : "لم يتم الدفع حتى الان"
                    }}</span>
                </div>

                <div class="date d-flex justify-content-between" v-if="one.paymentType == 'tamara'">
                  <span>{{ one.tamaraId }}</span>
                </div>


              </div>
            </div>

            <div class="order-number">
              <div class="order-content">
                <div class="date d-flex justify-content-between mb-2">
                  <p>{{ $t("Subtotal") }}</p>
                  <span class="price">{{ Math.round(one.subTotal) }} {{ $t("SAR") }}</span>
                </div>
                <div class="date d-flex justify-content-between mb-2" v-if="one.discountValue > 0">
                  <p>{{ $t("rival") }}</p>
                  <span class="price">{{ Math.round(one.discountValue) }} {{ $t("SAR") }} ({{
                    one.discountId?.name
                  }})</span>
                </div>
                <div class="date d-flex justify-content-between mb-2">
                  <p>{{ $t("Delivery Service") }}</p>
                  <span class="price">{{ Math.round(one.delivaryPrice) }} {{ $t("SAR") }}</span>
                </div>

                <div class="date d-flex justify-content-between mb-2">
                  <p>{{ $t("taxValue") }} (15%)</p>
                  <span class="price">{{ Math.round(one.taxValue) }} {{ $t("SAR") }}</span>
                </div>

                <div class="date date2 d-flex justify-content-between mt-3">
                  <p class="total">{{ $t("Total") }}</p>
                  <span class="total">{{ Math.round(one.total) }} {{ $t("SAR") }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between total-products">
            <div class="order-number mt-4">
              <h6 class="mb-4">{{ $t("shipping address") }}</h6>
              <div class="order-content w-100">
                <div class="date d-flex align-items-center mb-2">
                  <img src="../../assets/images/map.svg" alt="" class="ml-5" />
                  <div class="content">
                    <h6>
                      {{ one.addressId?.firstName }} {{ one.addressId?.lastName }}
                    </h6>
                    <p>{{ one.addressId?.phone }}</p>
                    <p>{{ one.addressId?.address }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="order-number shipping-trace mt-5">
              <div class="order-content">
               
                <div class="date date3 d-flex justify-content-between mt-3">
                  <p class="total">{{ $t("Shipping Status") }}</p>
                  <span class="total">{{ $t(traceOrder) }}</span>
                </div>
                <div class="date d-flex justify-content-between mb-2"  v-for="i of traceOrderObj">
                  <p>{{i.type}}</p>
                  <span class="price">
                    {{$formatDate(new Date(i.event_time))}}
                  </span>
                </div>

                
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      traceOrder: null,
      traceOrderObj: [],

      body: {
        userName: null,
        email: null,
        phone: null,
        image: null,
      },
      one: {
        doneDate: new Date(),
        subTotal: 0,
        total: 0,
        discountValue: 0,
        delivaryPrice: 0,
        taxValue: 0,
        orderStatus: "new",
        paymentDone: false,
        discountId: {
          name: null,
        },
        addressId: {
          firstName: null,
          lastName: null,
          address: null,
          phone: null,
        },
        paymentType: null,
        cartItemsList: [],
      },
      id: null,
    };
  },
  methods: {
    getType(type) {
      return this.$t(type);
    },
    deleteo() {
      this.$http.post("cart/canceledOrder/" + this.id, {}).then((res) => {
        this.$swal({
          title: this.$t("Successfully Canceled Order"),
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        this.$http.post("cart/getOneById/" + this.id, {}).then((res) => {
          this.one = res.data.data;
        });
      });
    },

    trackOrder(id) {
      let obj = { _id: id };
      this.$http.post(`cart/trackShipping`, obj).then(
        (res) => {
          console.log("888888888888888", res.data);
          this.traceOrder = res.data.status;
          this.traceOrderObj = res.data.events;
        },
        (err) => {

        });
    }
  },
  async created() {
    this.$http.post("users/getUserById", {}).then((res) => {
      this.body = res.data;
    });
    this.id = this.$route.params.id;
    this.$http.post("cart/getOneById/" + this.id, {}).then((res) => {
      this.one = res.data.data;
      this.trackOrder(this.id); // استدعاء تتبع الشحن


    });


  },
};
</script>

<style>
.trace-events {
  list-style: none;
  padding: 0;
}

.trace-events li {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}
</style>
